import axios from 'axios';
  

  /* DEV  */
  // export const API_UR = 'http://127.0.0.1:8000';
  export const API_URL = 'http://127.0.0.1:3001/api';

  /*images HEALTH status PROD*/
  // export const API_URL = 'https://customerapi.papasandbeer.com/api'; 
  /*images HEALTH status DEV*/
  // export const API_URL = 'http://api-customers.wenatives.com/api';

  /*  PROD  */
  export const API_UR = 'https://api.lvinlife.com/';

  /* test */
  // export const API_UR = 'http://apitest.papasandbeer.com';

export const API_KEY_STRIPE = 'pk_live_51MBmJfL1kOuLIvot0johofpPXf3Ib77njIZ3EYYW49vkLEQP4JedhyRpQMZtyHc1fUtYfWdGOwd8HKKgB4R6yix8002f77SrIF';
// export const API_KEY_STRIPE = 'pk_test_F5otdRmIj0e4buAsu6EGszwt';
 
export const request = axios.create({
	baseURL 		: API_UR,
	responseType 	: 'json'
});

export const api = () => {

	let token = localStorage.getItem('ses-tok-pa-evt');

	token = JSON.parse(token);

	let AuthorizationToken = token.token_type+" "+token.access_token;

	return axios.create({
		baseURL 		: API_UR+'/api',
		responseType 	: 'json',
		headers 		: {'Authorization': AuthorizationToken }
	});

}

export const api_file = () => {

	let token = localStorage.getItem('ses-tok-pa-evt');

	token = JSON.parse(token);

	let AuthorizationToken = token.token_type+" "+token.access_token;

	return axios.create({
		baseURL 		: API_UR+'/api',
		responseType 	: 'blob',
		headers 		: {'Authorization': AuthorizationToken,'Content-Type': 'multipart/form-data', }

	});

}


