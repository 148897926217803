export const admin_nav =
{
    items:
    [

        {
            title: true,
            name: 'Services',
            wrapper: {
                element: '',
                attributes: {}    
            },
            class: ''             
        },
        {
            name: 'Dash',
            url: '/app/admindash',
            icon: 'icon-graph',
        },
        // {
        //     name: 'Events',
        //     url: '/app/dashboard',
        //     icon: 'icon-folder-alt',
        // },
        {
            name: 'Products',
            url: '/app/MultipleEvents',
            icon: 'icon-folder-alt'
        },
        // {
        //     name: 'Shuttle',
        //     url: '/app/shuttle',
        //     icon: 'icon-folder-alt'
        // },
        // {
        //     name: 'Check Shuttle',
        //     url: '/app/shuttle-check',
        //     icon: 'icon-check'
        // },
        {
            name : 'Transactions',
            url : '/app/transactions',
            icon : 'icon-list'
        },
        // {
        //     name : 'Payment Plans',
        //     url : '/app/payment_plans',
        //     icon : 'icon-list'
        // },
        {
            name : 'Check-in',
            url : '/app/checkin',
            icon : 'icon-check'
        },
        {
            name: 'Wristband',
            url: '/app/banditas',
            icon: 'icon-support'
        },
        {
            name: 'Guest List',
            url: '/app/guests',
            icon: 'icon-support'
        },
        ,{
            name: 'Promo Codes',
            url: '/app/promo_codes',
            icon: 'icon-settings' 
        },
        {
            name: 'Reports',
            url: '/app/autoreports',
            icon: 'icon-settings' 
        },
        {
            name: 'Config',
            url: '/app/config',
            icon: 'icon-settings' 
        },
        // {
        //     name: 'AccessCodes',
        //     url: '/app/access_code_requests',
        //     icon: 'icon-settings' 
        // },
        {
            name: 'Customers',
            url: '/app/customer',
            icon: 'icon-user',
        },
        {
            name: 'Users',
            url: '/app/usuarios',
            icon: 'icon-user',
        },
        { divider: true, },
        {
            name: 'Exit',
            url: '/app/logout',
            icon: 'icon-logout',
        },
    ],
};

export const admin_check = {
    items:
    [
        {
            name: 'Dash',
            url: '/app/admindash',
            icon: 'icon-graph',
        },
        // {
        //     name: 'Dash-Temp',
        //     url: '/app/admindash-temp',
        //     icon: 'icon-graph',
        // },
        {
            name: 'Transactions',
            url: '/app/transactions',
            icon: 'icon-user',
        },
        {
            name: 'Customers',
            url: '/app/customer',
            icon: 'icon-user',
        },
        {
            name: 'Wristband',
            url: '/app/banditas',
            icon: 'icon-support'
        },
        // {
        //     name: 'Wristband-temp',
        //     url: '/app/banditas-temp',
        //     icon: 'icon-support'
        // },
        {
            name: 'Tickets',
            url: '/app/ticket',
            icon: 'icon-user',
        },
        {
            name : 'Check-in',
            url : '/app/checkin',
            icon : 'icon-check'
        },
        // {
        //     name : 'Check-in-temp',
        //     url : '/app/checkin-temp',
        //     icon : 'icon-check'
        // },
        { divider: true, },
        {
            name: 'Exit',
            url: '/app/logout',
            icon: 'icon-logout',
        },
    ]
}

export const check_in =
{
    items:
    [
        {
            title: true,
            name: 'Services',
            wrapper: {
                element: '',
                attributes: {}    
            },
            class: ''             
        },
        {
            name : 'Check-in',
            url : '/app/checkin',
            icon : 'icon-check'
        },
        { divider: true, },
        {
            name: 'Exit',
            url: '/app/logout',
            icon: 'icon-logout',
        },
    ],
};

export const onsitetest =
{
    items:
    [
        {
            title: true,
            name: 'Services',
            wrapper: {
                element: '',
                attributes: {}    
            },
            class: ''             
        },
        // {
        //     name: 'On site tests',
        //     url: '/app/onsitetest',
        //     icon: 'icon-user',
        // },
        {
            name: 'Guest List',
            url: '/app/guests',
            icon: 'icon-support'
        },
        { divider: true, },
        {
            name: 'Exit',
            url: '/app/logout',
            icon: 'icon-logout',
        },
    ],
};

export const check_in_temp =
{
    items:
    [
        {
            title: true,
            name: 'Services',
            wrapper: {
                element: '',
                attributes: {}    
            },
            class: ''             
        },
        {
            name : 'Check-in',
            url : '/app/checkin-temp',
            icon : 'icon-check'
        },
        { divider: true, },
        {
            name: 'Exit',
            url: '/app/logout',
            icon: 'icon-logout',
        },
    ],
};

export const customer_service =
{
    items:
    [
        {
            title: true,
            name: 'Services',
            wrapper: {
                element: '',
                attributes: {}    
            },
            class: ''             
        },
        {
            name : 'Transactions Customer service',
            url : '/app/transactions-cs',
            icon : 'icon-list'
        },
        {
            name: 'Guest List',
            url: '/app/guests',
            icon: 'icon-support'
        },
        {
            name: 'Customers',
            url: '/app/customer',
            icon: 'icon-user',
        },
        { divider: true, },
        {
            name: 'Exit',
            url: '/app/logout',
            icon: 'icon-logout',
        },
    ],
};


export const check_shuttle =
{
    items:
    [
        {
            title: true,
            name: 'Services',
            wrapper: {
                element: '',
                attributes: {}    
            },
            class: ''             
        },
        {
            name : 'Check-in',
            url : '/app/checkin',
            icon : 'icon-check'
        },
        {
            name: 'Check Shuttle',
            url: '/app/shuttle-check',
            icon: 'icon-check'
        },
        { divider: true, },
        {
            name: 'Exit',
            url: '/app/logout',
            icon: 'icon-logout',
        },

    ],
};

export const Affiliate =
{
    items:
    [
        {
            name: 'Products',
            url: '/app/MultipleEvents',
            icon: 'icon-folder-alt'
        },
        { divider: true, },
        {
            name: 'Exit',
            url: '/app/logout',
            icon: 'icon-logout',
        },

    ],
};

